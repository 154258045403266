import PropTypes from 'prop-types';

const InputField = ({
  label,
  type,
  id,
  autoComplete,
  value,
  onChange,
  required,
}) => {
  return (
    <div className="contact__input--container">
      <label htmlFor={id} className="contact__label">
        {label}
      </label>
      <input
        type={type}
        autoComplete={autoComplete}
        className="contact--input"
        id={id}
        value={value}
        onChange={onChange}
        name={id}
        required={required}
      />
    </div>
  );
};

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
};

export default InputField;
