import PropTypes from 'prop-types';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

const ArrowLink = React.memo(function ArrowLink({ destination, text }) {
  return (
    <Link to={destination} className="btn primcolor-bg btn--arrow click">
      <p className="btn--text">{text}</p>
      <FaArrowRight></FaArrowRight>
    </Link>
  );
});

ArrowLink.displayName = 'ArrowLink';

ArrowLink.propTypes = {
  destination: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ArrowLink;
