import PropTypes from 'prop-types';

function Scroll({ anchor }) {
  return (
    <a aria-label="Scroll Down" href={`#${anchor}`} className="scroll">
      <div className="scroll__icon click"></div>
    </a>
  );
}

Scroll.propTypes = {
  anchor: PropTypes.string.isRequired,
};

export default Scroll;
