import PropTypes from 'prop-types';

const ExpertPoint = ({ title, para, Icon }) => {
  return (
    <div className="expert__point">
      <div className="expert__icon--wrapper">
        {Icon && <Icon className="expert__icon" />}
      </div>
      <div className="expert__info--container">
        <h1 className="expert__title">{title}</h1>
        <p className="expert__para">{para}</p>
      </div>
    </div>
  );
};

ExpertPoint.propTypes = {
  title: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
  Icon: PropTypes.elementType.isRequired,
};

export default ExpertPoint;
