import '../style/Services/Services.scss';
import '../style/Services/Breakpoints.scss';
import ArrowLink from '../components/UI/ArrowLink';
import Expertise from '../components/Services/Expertise';

import whiteNoise from '../assets/servicesBg.svg';
import PackagesSection from '../components/Packages/PackagesSection';
import FAQ from '../components/Packages/FAQ';
import useDocumentTitle from '../lib/useDocumentTitle';

const Services = () => {
  useDocumentTitle('Services');

  return (
    <main id="services-page">
      <section id="services-landing">
        <div className="container">
          <div className="row">
            <div className="services__hero--wrapper">
              <div className="services__bg--wrapper">
                <img src={whiteNoise} alt="" />
              </div>
              <div className="services__hero--info-container">
                <h1 className="title">
                  Discover Our Comprehensive Web Services
                </h1>
                <p className="para sub-title">
                  AG Web Studios offers a full suite of web services to elevate
                  your online presence. From design to development, we have you
                  covered.
                </p>
                <ArrowLink destination={'/contact'} text="Let's Chat!" />
              </div>
              <figure className="services__hero--img-wrapper">
                <img
                  src="https://img.freepik.com/free-photo/fantasy-eye-illustration_23-2151675421.jpg"
                  alt=""
                  className="services__hero--img"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <Expertise />
      <PackagesSection />
      <FAQ />
    </main>
  );
};

export default Services;
