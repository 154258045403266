import PropTypes from 'prop-types';
import React from 'react';
import { FaArrowRight } from 'react-icons/fa6';

function ArrowBTN({ text, onClick }) {
  return (
    <button onClick={onClick} className="btn btn--arrow click">
      <p className="btn--text">{text}</p>
      <FaArrowRight></FaArrowRight>
    </button>
  );
}

ArrowBTN.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ArrowBTN;
