import PropTypes from 'prop-types';

const TextareaField = ({ label, id, value, onChange }) => {
  return (
    <div className="contact__input--container">
      <label htmlFor={id} className="contact__label">
        {label}
      </label>
      <textarea name={id} id={id} value={value} onChange={onChange}></textarea>
    </div>
  );
};

TextareaField.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

TextareaField.defaultProps = {
  value: '',
};

export default TextareaField;
