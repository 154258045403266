import React from 'react';
import Skill from '../UI/Home/Skill';
import SkillBar from './SkillBar';

function Skills() {
  return (
    <section id="skills">
      <div className="container">
        <div className="row">
          <h1 className="title">Our Skills</h1>
          <div className="section-line"></div>
          <div className="skills--container">
            <Skill
              para={
                'With over 5 years of experience in web design and development, we have the technical expertise and creative skills to create beautiful, functional websites that meet your specific needs.'
              }
              title={'Over 5 years of experience in web design and development'}
              category={'Experience'}
            ></Skill>
            <Skill
              para={
                'We have collaborated with a range of industries, from small businesses to large corporations, providing customized solutions to transform their digital presence.'
              }
              title={
                'Collaboration with a range of industries, from small businesses to large corporations'
              }
              category={'Expterise'}
            ></Skill>
            <Skill
              para={
                'Our skills in creating custom websites are unparalleled, with a focus on delivering high-quality designs that meet your unique needs and exceed your expectations.'
              }
              title={
                'Skills in creating custom websites that meet your unique needs'
              }
              category={'Experience'}
            ></Skill>
          </div>
          <div className="skills__bars--container">
            <SkillBar
              index={1}
              title={'Web Design'}
              id={'webdesign'}
            ></SkillBar>
            <SkillBar
              index={2}
              title={'Customer Service'}
              id={'customerservice'}
            ></SkillBar>
            <SkillBar
              index={3}
              title={'Project Management'}
              id={'projectmanagement'}
            ></SkillBar>
            <SkillBar
              index={4}
              title={'Responsive Design'}
              id={'responsivedesign'}
            ></SkillBar>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Skills;
