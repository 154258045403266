import PropTypes from 'prop-types';

const Skeleton = ({ width, height, borderRadius, classN }) => {
  return (
    <div
      className={`skeleton-box ${classN}`}
      style={{
        height,
        borderRadius,
        width,
      }}
    ></div>
  );
};

Skeleton.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  borderRadius: PropTypes.string.isRequired,
  classN: PropTypes.string.isRequired,
};

Skeleton.defaultProps = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  classN: '',
};

export default Skeleton;
