import React from 'react';
import Skeleton from '../Skeleton';

function PackageSkel() {
  return (
    <div className="package">
      <Skeleton classN={'package--title'} height={26} width={120}></Skeleton>
      <Skeleton classN={'package--price'} height={60} width={200}></Skeleton>
      <Skeleton
        classN={'package__price-per'}
        height={20}
        width={208}
      ></Skeleton>
      <Skeleton classN={'package__exclusive'} height={15} width={92}></Skeleton>
      <div className="package--items">
        <Skeleton classN={'package--item'} height={30}></Skeleton>
        <Skeleton classN={'package--item'} height={30}></Skeleton>
        <Skeleton classN={'package--item'} height={30}></Skeleton>
        <Skeleton classN={'package--item'} height={30}></Skeleton>
      </div>
      <Skeleton classN={'package--btn'} height={60}></Skeleton>
    </div>
  );
}

export default PackageSkel;
