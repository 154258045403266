import Story from '../../assets/Story.svg';

const OurStory = () => {
  return (
    <section id="story">
      <div className="container">
        <div className="row flex-row">
          <div className="left">
            <h1 className="title">Our Story</h1>
            <p className="para">
              What started as a young coder’s hobby has grown into a
              full-fledged web studio. Aksel’s early projects taught him the
              value of creativity and precision, skills he’s carried into
              building AG Web Studios. With a drive to make complex technology
              accessible, our story is one of continuous learning and a mission
              to simplify the web for everyone.
            </p>
          </div>
          <div className="right">
            <figure className="img--wrapper">
              <img src={Story} alt="Our Story" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurStory;
