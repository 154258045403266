import React from 'react';
import Question from '../UI/Packages/Question';

function FAQ() {
  return (
    <section id="faq">
      <div className="container">
        <div className="row">
          <h1 className="title">Frequently Asked Questions</h1>
          <div className="faq--container">
            <Question
              title={
                'Are there any hidden fees not mentioned in the descriptions?'
              }
              para={
                'No, all fees and charges are transparent and clearly outlined in the package descriptions. There are no hidden fees associated with our services. If you have any questions we recommend you contact us!'
              }
            ></Question>
            <Question
              title={
                'Is there flexibility in pricing for custom requirements not covered by the predefined packages?'
              }
              para={
                'Yes, we offer flexible pricing for custom requirements not covered by the predefined packages. Please contact us to discuss your specific needs and receive a tailored pricing quote.'
              }
            ></Question>
            <Question
              title="How does the process work?"
              para="Our process involves understanding your requirements, planning, designing, developing, and launching your website. We ensure continuous support throughout the project."
            />
            <Question
              title={
                'Can I negotiate the pricing for a package if I have specific budget constraints?'
              }
              para={
                'While our prices are competitive, we understand that budget constraints may vary. We are open to discussing pricing options based on your specific needs and budget. Please feel free to reach out to us to discuss further.'
              }
            ></Question>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
