import React, { useEffect, useRef, useCallback, memo } from 'react';
import { Link } from 'react-router-dom';
import '../style/NotFound.scss';
import HALO from 'vanta/src/vanta.halo';
import useDocumentTitle from '../lib/useDocumentTitle';

const NotFound = memo(() => {
  useDocumentTitle('404');
  const vantaRef = useRef(null);

  const initVanta = useCallback(() => {
    if (vantaRef.current) {
      vantaRef.current.destroy();
    }
    vantaRef.current = HALO({
      el: '#notfound',
      mouseControls: true,
      touchControls: false,
      gyroControls: false,
      minHeight: 200.0,
      minWidth: 200.0,
      scale: 1.0,
      scaleMobile: 1.0,
      backgroundColor: 0x232325,
    });
  }, []);

  useEffect(() => {
    initVanta();
    return () => {
      if (vantaRef.current) vantaRef.current.destroy();
    };
  }, [initVanta]);

  return (
    <main id="notfound--page">
      <section id="notfound">
        <div className="container">
          <div className="row">
            <h1 className="notfound--title primcolor">404</h1>
            <p className="notfound--para">
              Sorry, but we couldn&apos;t find the page you&apos;re looking for
              :(
            </p>
            <Link to="/" className="btn notfound--btn">
              Home
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
});

NotFound.displayName = 'NotFound';
export default NotFound;
