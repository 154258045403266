import PropTypes from 'prop-types';
import React from 'react';
import { FaCheck } from 'react-icons/fa6';
import { Link } from 'react-router-dom';

function Package({ pack }) {
  if (!pack || !Array.isArray(pack.items)) {
    return null; // Return null or handle the case gracefully
  }

  return (
    <div className="package">
      <h1 className="package--title">{pack.name}</h1>
      <h2 className="package--price">
        {pack.pricePerPage}€ <span className="price__per">/page</span>
      </h2>
      <p className="package__price-per">+ 100€ start price</p>
      <p className="package__exclusive">exclusive of VAT</p>
      <Link
        to={`/contact/${pack.name.toLowerCase()}`}
        className="package--btn click"
      >
        Get Started
      </Link>
      <div className="package--items">
        {pack.items.map((item, index) => (
          <div key={`${index}-${item}`} className="package--item">
            <FaCheck className="package__item--icon"></FaCheck>
            <p className="package__item--text">{item}</p>
          </div>
        ))}
      </div>
      {pack.para ? <p className="package--para">{pack.para}</p> : <></>}
    </div>
  );
}

Package.propTypes = {
  pack: PropTypes.object.isRequired,
};

export default Package;
