import PropTypes from 'prop-types';

const Button = ({ onClick, primary, children }) => {
  return (
    <button
      onClick={onClick || null}
      className={`btn click ${primary && 'primcolor-bg'}`}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Button.defaultProps = {
  onClick: null,
  primary: false,
};

export default Button;
