import FounderImg from '../../assets/Founder.JPEG';

const Founder = () => {
  const calculateAge = () => {
    const birthdate = new Date(2008, 9, 22);
    const today = new Date();
    let age = today.getFullYear() - birthdate.getFullYear();

    const monthDiff = today.getMonth() - birthdate.getMonth();
    const dayDiff = today.getDate() - birthdate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--; // Adjust age if the birthday hasn't occurred yet this year
    }

    return age;
  };

  return (
    <section id="founder">
      <div className="container">
        <div className="row flex-row">
          <div className="left">
            <figure
              data-aos="fade-right"
              data-aos-delay="100"
              className="founder__img--wrapper img--wrapper"
            >
              <img src={FounderImg} alt="Founder" />
            </figure>
          </div>
          <div className="right">
            <span
              data-aos-delay="100"
              data-aos="fade-right"
              className="founder__top-short"
            >
              Welcome to the world of captivating web design!
            </span>
            <h1
              data-aos-delay="200"
              data-aos-offset="100"
              data-aos="fade-right"
              className="title founder__title"
            >
              I&apos;m Aksel Glyholt
            </h1>
            <p
              data-aos-delay="300"
              data-aos-offset="200"
              data-aos="fade-right"
              className="para"
            >
              At the heart of AG Web Studios is Aksel Glyholt, a danish{' '}
              {calculateAge()}-year-old entrepreneur with a passion for turning
              ideas into digital solutions. Starting his coding journey at the
              age of 9, Aksel mastered programming by creating games on Roblox,
              sparking a love for problem-solving and user-focused design.
              Today, he channels that passion into helping clients achieve
              stress-free web solutions tailored to their needs.
            </p>
            <div
              className="stats--container"
              data-aos="fade-right"
              data-aos-delay="400"
            >
              <div data-aos="fade-right" data-aos-delay="400" className="stat">
                <span className="stat--number">
                  {(calculateAge() - 9).toString().padStart(2, '0')}
                  <span className="stat-plus">+</span>
                </span>
                <span className="stat--text">Years of Experience</span>
              </div>
              <div data-aos="fade-right" data-aos-delay="500" className="stat">
                <span className="stat--number">
                  05<span className="stat-plus">+</span>
                </span>
                <span className="stat--text">Industries Served</span>
              </div>
              <div data-aos="fade-right" data-aos-delay="600" className="stat">
                <span className="stat--number">
                  100
                  <span className="stat-plus">%</span>
                </span>
                <span className="stat--text">Focus on Results</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Founder;
