import React from 'react';
import Testimonial from '../UI/Home/Testimonial';
import vertigoesPFP from '../../assets/PFPs/VertigoesPFP.jpg';
import IngridPFP from '../../assets/PFPs/IngridPFP.jpg';

const testimonials = [
  {
    text: 'AG Web Studios has captured our visual musical expression. AG Web has managed to integrate music videos and images in the best possible way. We have been very satisfied with the collaboration.',
    name: 'The Vertigoes',
    job: 'Indie Band',
    pfp: vertigoesPFP,
  },
  {
    text: "I couldn't be happier with the service I received from AG web Studios. They have been helping me with my homepage and webshop. Quick, efficient, and incredibly insightful. They found smart solutions to my IT challenges and made everything seem easy. Highly recommended!!",
    name: 'Ingrid Riis',
    job: 'Freelance Photographer',
    pfp: IngridPFP,
  },
];

function Testimonials() {
  return (
    <section id="testimonials">
      <div className="container">
        <div className="row">
          <h1 className="testimonials__title title">
            What Our <span className="primcolor">Clients</span> Say
          </h1>
          <div className="section-line"></div>
          <div className="testimonials--container">
            <Testimonial testimonials={testimonials} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
