import React, { useEffect, useRef, useState } from 'react';
import TextareaField from '../UI/Contact/TextareaField';
import ArrowBTN from '../UI/ArrowBTN';
import InputField from '../UI/Contact/InputField.jsx';
import { sendForm } from '../../lib/sendForm.js';
import { FaCheck } from 'react-icons/fa6';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useParams } from 'react-router-dom';

const ContactForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [captcha, setCaptcha] = useState();
  const [captchaError, setCaptchaError] = useState(false);

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const { product } = useParams();
  const navigate = useNavigate();

  const recaptchaRef = useRef();

  useEffect(() => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, [recaptchaRef]);

  // Clean up recaptcha
  useEffect(() => {
    return () => {
      const element = document.querySelector('.g-recaptcha-bubble-arrow');

      if (element) {
        element.parentElement.remove();
      }
    };
  }, [recaptchaRef]);

  const handleFormSubmit = (event) => {
    setCaptchaError(false);
    event.preventDefault();
    const data = {
      'first-name': firstName,
      'last-name': lastName,
      email: email,
      phone: phone,
      about: projectDescription,
      'company-name': companyName,
      'g-recaptcha-response': captcha,
      product: product,
    };

    if (!captcha) {
      setCaptchaError(true);
      return;
    }
    setLoading(true);

    sendForm(data)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        recaptchaRef.current.reset();
      })
      .catch((error) => {
        alert('Something went wrong! \nContact us at: contact@agweb.studio');
        console.error('Error:', error);
        setLoading(false);
      });
  };

  return (
    <form action="" id="contact--form" onSubmit={handleFormSubmit}>
      {success ? (
        <div className="contact--success">
          <FaCheck />
        </div>
      ) : (
        <></>
      )}
      {loading ? (
        <div className="contact--loading">
          <div className="loader"></div>
        </div>
      ) : (
        <></>
      )}
      <div className="same-line">
        <InputField
          label="First Name"
          type="text"
          autoComplete="given-name"
          id="first-name"
          value={firstName}
          onChange={(event) => setFirstName(event.target.value)}
          required
        />
        <InputField
          label="Last Name"
          type="text"
          autoComplete="family-name"
          id="last-name"
          value={lastName}
          onChange={(event) => setLastName(event.target.value)}
          required
        />
      </div>
      <InputField
        label="Company name (optional)"
        type="text"
        id="company-name"
        value={companyName}
        autoComplete={''}
        onChange={(event) => setCompanyName(event.target.value)}
      />
      <div className="same-line">
        <InputField
          label="Email"
          type="email"
          autoComplete="email"
          id="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          required
        />
        <InputField
          label="Phone"
          type="tel"
          autoComplete="tel"
          id="phone"
          pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
          value={phone}
          onChange={(event) => setPhone(event.target.value)}
          required
        />
      </div>
      {product && (
        <div className="contact__input--container">
          <label htmlFor="product" className="contact__label">
            Product
          </label>
          <select
            className="contact--input"
            onChange={(event) => navigate(`/contact/${event.target.value}`)}
            name="product"
            id="product"
            defaultValue={product || 'starter'}
          >
            <option value="starter">Starter</option>
            <option value="professional">Professional</option>
            <option value="enterprise">Enterprise</option>
            <option value="custom">Custom</option>
          </select>
        </div>
      )}
      <TextareaField
        label="Tell us about your project (optional)"
        id="about"
        value={projectDescription}
        onChange={(event) => setProjectDescription(event.target.value)}
      />
      <ReCAPTCHA
        size="normal"
        ref={recaptchaRef}
        sitekey="6Ld2Y2YpAAAAACJtK_DXo1MWd-oz1WhvRJ5GrOCE"
        onChange={setCaptcha}
        required
        onExpired={() => {
          setCaptcha(null);
          setCaptchaError(true);
        }}
      />
      <p className="captcha__error" hidden={!captchaError}>
        Please complete the reCAPTCHA verification to proceed.
      </p>
      <ArrowBTN text={'Send request'} />
    </form>
  );
};

export default ContactForm;
