import React, { useEffect, useState } from 'react';
import Package from '../UI/Packages/Package';
import axios from 'axios';
import PackageSkel from '../UI/Packages/PackageSkel';

function PackagesSection() {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      const result = await axios.get(
        'https://files.agweb.studio/?file=Packages.json'
      );

      if (result.data && !result.data.error) {
        setPackages(result.data);
      }
    };

    fetchPackages();
  }, []);

  return (
    <section id="packages">
      <div className="container">
        <div className="row">
          <h1 className="packages--title title">Transparent Pricing Plans</h1>
          <div className="section-line"></div>
          <h2 className="packages--sub-title sub-title">
            Choose a plan that fits your needs and budget. Start your journey
            with AG Web Studios today.
          </h2>
          <div className="packages--container">
            {packages.length === 0
              ? [...Array(4)].map((e, i) => <PackageSkel key={i}></PackageSkel>)
              : packages.map((pack) => (
                  <Package key={pack.name} pack={pack}></Package>
                ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PackagesSection;
