import PropTypes from 'prop-types';

const Service = ({ title, text, icon, delay }) => {
  return (
    <div
      data-aos="fade-right"
      data-aos-delay={delay || '100'}
      className="service col"
    >
      <span className="service--icon primcolor">{icon}</span>
      <h1 className="service--title">{title}</h1>
      <p className="service--para">{text}</p>
    </div>
  );
};

Service.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  delay: PropTypes.string,
};

Service.defaultProps = {
  delay: '100',
};

export default Service;
