import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FiPlus, FiMinus } from 'react-icons/fi';

function Question({ title, para }) {
  const [open, setOpen] = useState(false);

  return (
    <div className={`question ${open && 'question--open'}`}>
      <div onClick={() => setOpen(!open)} className="question--top">
        <h1 className="faq--title">{title}</h1>
        <div className="question--icon">{!open ? <FiPlus /> : <FiMinus />}</div>
      </div>
      <p className="faq--para para">{para}</p>
    </div>
  );
}

Question.propTypes = {
  title: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
};

export default Question;
