import React from 'react';
import chatImage from '../../assets/PackagesPage/undraw_typing_re_d4sq.svg';
import ArrowBTN from '../UI/ArrowLink';

function GuidanceSection() {
  return (
    <section id="packages--guidance">
      <div className="container">
        <div className="row">
          <div className="packages__guidance--left left">
            <h1 className="packages__guidance--title title">
              Unsure Which Package to Pick?
            </h1>
            <div className="section-line"></div>
            <p className="packages__guidance--para para">
              Not sure which package best suits your needs? Don&apos;t worry,
              we&apos;re here to help. Our team is ready to provide personalized
              guidance tailored to your specific requirements. Contact us today
              and let us assist you in choosing the perfect package for your
              project.
            </p>
            <ArrowBTN destination={'/contact'} text={'Chat with us'}></ArrowBTN>
          </div>
          <div className="packages__guidance--right right">
            <figure className="img--wrapper">
              <img src={chatImage} alt="Chatting" className="img" />
            </figure>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GuidanceSection;
