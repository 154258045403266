import React from 'react';
import image from '../../assets/teamCollab.webp';
import ArrowLink from '../UI/ArrowLink';

function AboutSec() {
  return (
    <div id="aboutsec">
      <div className="container">
        <div className="flex-row">
          <div className="aboutsec--left left">
            <h1 className="aboutsec--title title">Who We Are</h1>
            <div className="section-line"></div>
            <p className="aboutsec--para para">
              At AG Web Studios, we are passionate about creating beautiful and
              functional websites that meet your unique needs. Our team is
              comprised of experts in web design, project management, and
              customer service, providing a comprehensive suite of skills to
              transform your digital presence.
            </p>
            <ArrowLink
              destination={'/about'}
              text={'Read More About Us'}
            ></ArrowLink>
          </div>
          <div className="aboutsec--right right">
            <figure className="aboutsec__img--wrapper img--wrapper">
              <img src={image} alt="" className="aboutsec__img" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSec;
