import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  // Extracts pathname property (key) from the location object
  const { pathname } = useLocation();

  // Automatically scrolls to the top whenever pathname changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  // Since this component does not render anything, return null
  return null;
};

export default ScrollToTop;
