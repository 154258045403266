import PropTypes from 'prop-types';

function Skill({ title, para, category }) {
  return (
    <div className="skill">
      <p className="skill__category primcolor">{category}</p>
      <h1 className="skill--title">{title}</h1>
      <p className="skill--para para">{para}</p>
    </div>
  );
}

Skill.propTypes = {
  title: PropTypes.string.isRequired,
  para: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
};

export default Skill;
