import emailjs from '@emailjs/browser';

const sendForm = async (target) => {
  const clientData = {};

  const data = {
    ...target,
    ...clientData,
  };

  try {
    const result = emailjs
      .send('service_oa219q9', 'template_q0zh8uj', data, 'Gil_NuAHZ8sgAhBVN')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((error) => {
        console.error('Error sending message: ' + error);
      });

    return result;
  } catch (error) {
    throw error;
  }
};

export { sendForm };
