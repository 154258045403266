import React, { useCallback, useEffect, useState } from 'react';
import Logo from '../assets/AGWebLogo-Full.webp';
import { Link, useLocation } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { IoCloseSharp } from 'react-icons/io5';

import ArrowLink from './UI/ArrowLink';

function Nav() {
  const [isHidden, setIsHidden] = useState(false);
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [containsWhiteNavRoute, setContainsWhiteNavRoute] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // Track the last scroll position
  const [scrolled, setScrolled] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const whiteNavRoutes = [
      'contact',
      'cookie-policy',
      'privacy-policy',
      'pricing',
      'services',
    ];

    const currentPath = location.pathname;

    setContainsWhiteNavRoute(
      whiteNavRoutes.some((route) => currentPath.includes(route))
    );
  }, [location]);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > 10) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    if (currentScrollY > 100 && currentScrollY > lastScrollY) {
      // User scrolled down past 100px
      setIsHidden(true);
    } else if (currentScrollY < lastScrollY) {
      // User scrolled up
      setIsHidden(false);
    }

    setLastScrollY(currentScrollY); // Update the last scroll position
  }, [lastScrollY]);

  useEffect(() => {
    // Add event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, handleScroll]);

  const toggleSidebar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const handleSidebarClick = (event) => {
    if (
      event.target.tagName.toLowerCase() === 'a' &&
      event.target.closest('.sidebar')
    ) {
      setSideBarOpen(false);
    }
  };

  return (
    <>
      <nav
        className={`${isHidden ? 'hidden-nav' : ''} ${
          containsWhiteNavRoute || scrolled ? 'white-nav' : ''
        }`}
      >
        <div className="container">
          <div className="row">
            <div className="header--left">
              <figure className="header__logo--wrapper">
                <Link aria-label="Home page" to={'/'}>
                  <img src={Logo} alt="" className="header__logo" />
                </Link>
              </figure>
            </div>
            <div className="header--center">
              <ul className="header--links">
                <li className="header__link">
                  <Link to={'/'}>Home</Link>
                </li>
                <li className="header__link">
                  <Link to={'/services'}>Services</Link>
                </li>
                <li className="header__link">
                  <Link to={'/about'}>About</Link>
                </li>
              </ul>
            </div>
            <div className="header--right">
              <ArrowLink destination={'contact'} text="Contact" />
              <button
                aria-label="Sidebar"
                onClick={toggleSidebar}
                className="sidebar--btn"
              >
                {sideBarOpen ? (
                  <IoCloseSharp></IoCloseSharp>
                ) : (
                  <RxHamburgerMenu></RxHamburgerMenu>
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`sidebar ${sideBarOpen ? 'sidebar-open' : ''}`}
        onClick={handleSidebarClick}
      >
        <ul className="sidebar--links">
          <li className="sidebar__link">
            <Link to={'/'}>Home</Link>
          </li>
          <li className="sidebar__link">
            <Link to={'/about'}>About</Link>
          </li>
          <li className="sidebar__link">
            <Link to={'/services'}>Services</Link>
          </li>
          <li className="sidebar__link">
            <Link to={'/contact'}>Contact</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Nav;
