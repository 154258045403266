import MissionImg from '../../assets/mission.svg';
import ArrowLink from '../UI/ArrowLink';

const OurMission = () => {
  return (
    <section id="mission">
      <div className="container">
        <div className="row flex-row">
          <div className="left">
            <figure className="img--wrapper">
              <img src={MissionImg} alt="Our Mission" />
            </figure>
          </div>
          <div className="right">
            <h1 className="title">Our Mission</h1>
            <p className="para">
              We understand that the digital world can feel overwhelming. That’s
              why our mission is simple: to take the stress off your shoulders.
              From concept to launch, we manage every step of the process,
              delivering a seamless experience that leaves you with a website
              you’re proud of. With AG Web Studios, your vision becomes
              reality—without the complexity.
            </p>
            <ArrowLink destination="/contact" text="Get in touch!" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurMission;
